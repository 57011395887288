<template>
  <div>
    <div class="bg-[#f1f1f1] py-6 sm:py-8 lg:py-12">
      <div class="mx-auto ml-6 mr-5 max-w-screen-2xl px-4 md:px-8">
        <!-- text - start -->
        <div class="mb-10 md:mb-16">
          <h2
            class="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl"
          >
            Our Categories
          </h2>

          <!-- <p
            class="mx-auto max-w-screen-md text-center text-gray-500 md:text-md"
          >
            This is a section of some simple filler text, also known as
            placeholder text. It shares some characteristics of a real written
            text but is random or otherwise generated.
          </p> -->
        </div>

        <!-- <div class="py-4 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6"> -->

        <div
          class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0"
        >
          <div
            class="bg-[#fff] p-5 rounded-2xl shadow-2xl"
            @click="getChildren"
          >
            <div
              class="flex justify-center items-center mb-4 w-14 h-14 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900"
            >
              <svg
                class="w-10 h-10 text-[#2176ae] lg:w-14 lg:h-14 dark:text-primary-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <h3 class="mb-2 text-xl font-bold dark:text-white">
              For Kids (Ages 7+)
            </h3>
            <p class="text-gray-500 dark:text-gray-400">
              We offer a tailored approach to empower kids as young as 7 years
              old. Through our Mind Management model, we guide children in
              understanding and managing their emotions, cultivating growth
              mindsets, and introducing mindfulness techniques. Our courses are
              designed to equip kids with essential life skills and emotional
              intelligence, setting a strong foundation for their personal
              development.
            </p>
          </div>
          <div
            class="bg-[#fff] p-5 rounded-2xl shadow-2xl"
            @click="getTeenager"
          >
            <div
              class="flex justify-center items-center mb-4 w-14 h-14 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900"
            >
              <svg
                class="w-10 h-10 text-[#2176ae] lg:w-14 lg:h-14 dark:text-primary-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z"
                ></path>
              </svg>
            </div>
            <h3 class="mb-2 text-xl font-bold dark:text-white">
              For Teenagers
            </h3>
            <p class="text-gray-500 dark:text-gray-400">
              Teenagers face unique challenges and opportunities. Our e-learning
              platform provides comprehensive training in self-development,
              empowering teens to overcome limiting beliefs, cultivate grit, and
              develop mental toughness. We offer courses designed to help them
              navigate the adolescent years with confidence and resilience.
            </p>
          </div>
          <div class="bg-[#fff] p-5 rounded-2xl shadow-2xl" @click="getAdult">
            <div
              class="flex justify-center items-center mb-4 w-14 h-14 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900"
            >
              <svg
                class="w-10 h-10 text-[#2176ae] lg:w-14 lg:h-14 dark:text-primary-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
                <path
                  d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"
                ></path>
              </svg>
            </div>
            <h3 class="mb-2 text-xl font-bold dark:text-white">For Adults</h3>
            <p class="text-gray-500 dark:text-gray-400">
              We offer a wide range of courses suitable for all age groups,
              including adults. Our courses cover various topics like emotional
              intelligence, mindset development, mindfulness, and applying
              neuroscience in personal and professional life. With over 20
              unique courses, including "Make Your Mind Work," "Your
              Brain at Work," "Neurowisdom," "Dopamine Detox," "Rewire Your
              Anxiety Brain," and "Rewire Your Brain for Love,"' we provide
              valuable knowledge and tools for personal growth, regardless of
              your age or background.
            </p>
          </div>
        </div>
      </div>

      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "category-page",
  methods: {
    getChildren() {
      this.$router.push({ name: "ChildrenTopic" });
    },
    getAdult() {
      this.$router.push({ name: "AdultTopic" });
    },
    getTeenager() {
      this.$router.push({ name: "TeenagerTopic" });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>