import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import Login from "../views/Login";
import Register from "../views/Register";
import CategoryList from "../components/category/CategoryList";
import AdultTopic from "../views/topic/AdultTopic";
import ChildrenTopic from "../views/topic/ChildrenTopic";
import TeenagerTopic from "../views/topic/TeenagerTopic";
import Lessons from "../views/Lessons";
import LessonDetail from "../views/LessonDetail";
import AboutView from "@/views/AboutView.vue";
import Contact from "@/views/Contact.vue";
import PopularCourse from "@/components/PopularCourse.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/about",
    name: "AboutView",
    component: AboutView,
    meta: { requiresAuth: true },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { guest: true },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { guest: true },
  },
  {
    path: "/categoryList/:id",
    name: "CategoryList",
    component: CategoryList,
    meta: { requiresAuth: true },
  },
  {
    path: "/popular",
    name: "PopularCourse",
    component: PopularCourse,
    meta: { requiresAuth: true },
  },
  {
    path: "/lessons/:id",
    name: "Lessons",
    component: Lessons,
    meta: { requiresAuth: true },
  },
  {
    path: "/lesson-detail/:id",
    name: "LessonDetail",
    component: LessonDetail,
    meta: { requiresAuth: true },
  },
  {
    path: "/adultTopic",
    name: "AdultTopic",
    component: AdultTopic,
    meta: { requiresAuth: true },
  },
  {
    path: "/childrenTopic",
    name: "ChildrenTopic",
    component: ChildrenTopic,
    meta: { requiresAuth: true },
  },
  {
    path: "/teenagerTopic",
    name: "TeenagerTopic",
    component: TeenagerTopic,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["auth/isAuthenticated"]) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
