<template>
  <div class="pt-[5rem]">
    <div class="bg-[#f1f1f1] py-6 sm:py-8 lg:py-12">
      <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
        <div class="mb-6 flex items-end justify-between gap-4">
          <h2 class="text-2xl font-bold text-gray-800 lg:text-3xl">
            Popular Courses
          </h2>

          <!-- <a
            href="#"
            class="inline-block rounded-lg border bg-white px-4 py-2 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base"
            >Show more</a
          > -->
        </div>
        <Loader v-if="!popularCourses" />
        <div
          class="grid gap-x-4 gap-y-8 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-3 xl:grid-cols-4"
        >
          <!-- product - start -->
          <div v-if="!popularCourses || popularCourses.length === 0">
            <h2
              class="text-gray-700 justify-center text-lg fixed-center flex items-center gap-2"
            >
              <Icon icon="ic:round-warning" class="w-8 h-8 text-red-500" />
              <span> No items found</span>
            </h2>
          </div>
          <div
            v-else
            class="rounded-2xl bg-white shadow-2xl p-5"
            v-for="(lesson, index) in popularCourses"
            :key="index"
            @click="
              $router.push({
                name: 'Lessons',
                params: { id: lesson.id },
              })
            "
          >
            <div
              class="group relative mb-2 block h-80 overflow-hidden rounded-lg bg-gray-100 lg:mb-3"
            >
              <img
                :src="lesson.cover_image.url"
                loading="lazy"
                alt="Photo by Rachit Tank"
                class="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
              />
            </div>

            <div>
              <div
                class="hover:gray-800 mb-1 font-bold text-[#2176ae] transition duration-100 lg:text-lg"
              >
                {{ lesson.title.english }}
              </div>

              <div class="items-end gap-2 low-root">
                <!-- <span
                  class="font-bold float-left flex flex-row text-gray-800 lg:text-lg"
                  >ETB 15.00</span
                > -->
                <p class="float-left flex flex-row">
                  <svg
                    class="h-6 w-6 text-[#2176ae]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    /></svg
                  >{{ lesson.lessons_count }} Lessons
                </p>
              </div>
              <!-- <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" /> -->
              <!-- <div class="flow-root">
                <p class="float-left flex flex-row">
                  <svg
                    class="h-6 w-6 text-[#2176ae]"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <polyline points="12 6 12 12 16 14" /></svg
                  >18:15:02
                </p>
                
              </div> -->
            </div>
          </div>
          <!-- product - end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import Loader from "./Loader.vue";
export default {
  data() {
    return {
      popularCourses: null,
    };
  },
  mounted() {
    this.lessonTopic();
  },
  components: {
    Loader,
  },
  methods: {
    lessonTopic() {
      api
        .request({ auth: true })
        .get(`/popular-courses`)
        .then((response) => {
          this.popularCourses = response.data.data.data;
          console.log(response.data.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>