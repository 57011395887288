<template>
  <div>
    <div class="flex mt-[10rem] mb-10 ml-5 mr-5">
      <div class="w-1/5 p-5 shadow-2xl rounded-xl overflow-y-auto h-full">
        <h2 class="mb-2 text-xl font-bold text-gray-900 dark:text-white">
          Categories
        </h2>
        <ul
          class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400"
        >
          <router-link to="/childrenTopic">
            <li class="flex items-center">
              <svg
                class="h-8 w-8 text-[#0ea5e9] p-[0.1rem]"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"
                />
              </svg>
              Children Topic
            </li>
          </router-link>
          <router-link to="/teenagerTopic">
            <li class="flex items-center">
              <svg
                class="h-8 w-8 text-[#0ea5e9] p-[0.1rem]"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"
                />
              </svg>
              Teenagers Topic
            </li>
          </router-link>
          <router-link to="/adultTopic">
            <li class="flex items-center">
              <svg
                class="h-8 w-8 text-[#0ea5e9] p-[0.1rem]"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"
                />
              </svg>
              Adults Topic
            </li>
          </router-link>
        </ul>
      </div>
      <Loader v-if="!categoryTopics" />
      <div class="w-4/5 p-5 ml-10 bg-transparent shadow-2xl rounded-xl">
        <div class="">
          <div class="mx-auto max-w-screen-lg px-4 md:px-8">
            <div class="mb-6 flex flex-col gap-4 sm:mb-8 md:gap-6">
              <!-- product - start -->
              <!-- Conditional rendering block -->
              <div v-if="!categoryTopics || categoryTopics.length === 0">
                <h2
                  class="text-gray-700 justify-center text-lg fixed-center flex items-center gap-2"
                >
                  <Icon icon="ic:round-warning" class="w-8 h-8 text-red-500" />
                  <span> No items found</span>
                </h2>
              </div>
              <div
                v-else
                class="flex flex-wrap gap-x-4 overflow-hidden rounded-lg shadow-2xl sm:gap-y-4 lg:gap-6"
                v-for="(courses, index) in categoryTopics"
                :key="index"
                @click="
                  $router.push({
                    name: 'Lessons',
                    params: { id: courses.id },
                  })
                "
              >
                <div
                  class="group relative block h-48 w-40 overflow-hidden bg-gray-100 sm:h-56 sm:w-40"
                >
                  <img
                    :src="courses.cover_image.url"
                    loading="lazy"
                    alt="Photo by Thái An"
                    class="h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                  />
                </div>

                <div class="flex flex-1 flex-col justify-between py-4">
                  <div>
                    <div
                      class="mb-1 inline-block text-lg font-bold text-gray-800 transition duration-100 hover:text-gray-500 lg:text-xl"
                    >
                      {{ courses.title.english }}
                    </div>

                    <span class="block text-gray-500">{{
                      courses.description.english
                    }}</span>
                    <div>
                      <div class="flex flex-row p-2">
                        <span class="flex flex-row text-gray-500 pr-5">
                          <svg
                            class="h-6 w-6 text-[#2176ae]"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                            <polyline points="22 4 12 14.01 9 11.01" />
                          </svg>
                          {{ courses.lessons_count }} Lessons</span
                        >
                        <!-- <span class="flex flex-row text-gray-500 pl-5">
                          <svg
                            class="h-6 w-6 text-[#2176ae]"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                            <polyline points="22 4 12 14.01 9 11.01" />
                          </svg>
                          ETB 15.00</span
                        > -->
                      </div>

                      <div></div>
                    </div>
                  </div>
                  <div></div>
                </div>

                <div
                  class="flex w-full justify-between border-t p-4 sm:w-auto sm:border-none sm:pl-0 lg:p-6 lg:pl-0"
                ></div>
              </div>
            </div>
            <!-- totals - end -->
          </div>
        </div>
      </div>
    </div>
    <!-- end main section -->
  </div>
</template>

<script>
// import NavBar from "@/components/NavBar.vue";
import api from "@/services/api";
import Loader from "../Loader.vue";
export default {
  data() {
    return {
      categoryTopics: null,
      id: null,
    };
  },
  components: {
    Loader,
  },
  mounted() {
    this.id = this.$route.params.id;
    this.categoryTopic();
  },
  methods: {
    categoryTopic() {
      api
        .request({ auth: true })
        .get(`/get-courses/${this.id}`)
        .then((response) => {
          this.categoryTopics = response.data.data.data;
          console.log(response.data.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  background-image: linear-gradient(
      rgba(125, 181, 227, 0.9),
      rgba(35, 170, 204, 0.9)
    ),
    url(@/assets/image/courses.jpeg);
}
</style>