<template>
  <div class="mt-10">
    <div class="flex mt-[7rem]">
      <Loader v-if="!lessonsTopics" />
      <div class="w-full ml-5 mr-5 bg-transparent rounded-xl">
        <div class="">
          <div class="mx-auto max-w-screen-xl">
            <div class="flex flex-col gap-4" v-if="lessonsTopics">
              <video class="w-full h-[70vh] rounded-xl" controls>
                <source :src="lessonsTopics.video.url" type="video/mp4" />
              </video>
              <div class="w-4/5 mb-10 ml-5">
                <h2 class="text-4xl font-extrabold">
                  {{ lessonsTopics.title.english }}
                </h2>
                <p class="my-2 text-lg text-gray-500">
                  {{ lessonsTopics.description.english }}
                </p>
              </div>
            </div>
          </div>
          <!-- totals - end -->
        </div>
      </div>
    </div>

    <!-- end main section -->
  </div>
</template>
    
    <script>
// import NavBar from "@/components/NavBar.vue";
import api from "@/services/api";
import Loader from "@/components/Loader.vue";
export default {
  name: "lessons-page",
  data() {
    return {
      lessonsTopics: null,
      lessonsCourse: null,
      id: null,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    console.log("lesson id", this.id);
    this.lessonTopic();
    this.lessonCourse();
  },
  methods: {
    lessonTopic() {
      api
        .request({ auth: true })
        .get(`/lesson-detail/${this.id}`)
        .then((response) => {
          this.lessonsTopics = response.data.data;
          console.log(response.data.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    lessonCourse() {
      api
        .request({ auth: true })
        .get(`/lesson-detail/${this.id}`)
        .then((response) => {
          this.lessonsCourse = response.data.data.course;
          console.log("lessons", response.data.data.course);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    // NavBar,
    Loader,
  },
};
</script>
    
    <style lang="scss" scoped>
section {
  background-image: linear-gradient(
      rgba(125, 181, 227, 0.9),
      rgba(35, 170, 204, 0.9)
    ),
    url(@/assets/image/courses.jpeg);
}
</style>