<template>
  <div
    class="bg-sky-300 fixed top-0 z-50 flex justify-between lg:py-3 sm:justify-around items-center w-screen"
  >
    <div class="logo sm:-ml-10 mt-2">
      <div class="flex items-start">
        <router-link
          to="/"
          class="text-xl font-bold text-[#6366f1] md:text-2xl hover:text-indigo-400"
        >
          <img class="h-15 w-10" src="@/assets/image/logo.png" />
        </router-link>
      </div>
    </div>
    <div class="sm:hidden">
      <button @click="toggleNav()">
        <svg
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Menu / Menu_Alt_03">
            <path
              id="Vector"
              d="M5 17H13M5 12H19M5 7H13"
              stroke="#000000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </button>
    </div>

    <div class="links ml-[10rem] mt-2 hidden sm:block">
      <ul
        class="flex-col md:flex md:space-y-0 md:flex-row md:items-center md:space-x-5 md:mt-0"
      >
        <router-link to="/">
          <li class="text-black hover:text-blue-700">Home</li></router-link
        >
        <router-link to="/about">
          <li class="text-black hover:text-blue-700">About Us</li></router-link
        >
        <router-link to="/popular">
          <li class="text-black hover:text-blue-700">
            Popular Course
          </li></router-link
        >
        <router-link to="/contact">
          <li class="text-black hover:text-blue-700">
            Contact Us
          </li></router-link
        >

        <span v-if="isLoggedIn">
          <li class="text-black hover:text-blue-700">
            <button
              @click="logout"
              class="bg-[#2176ae] hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-xl"
            >
              Logout
            </button>
          </li>
        </span>
        <span
          v-else
          class="lex-col md:flex md:space-y-0 md:flex-row md:items-center md:space-x-5 md:mt-0"
        >
          <li class="text-black hover:text-blue-700">
            <router-link to="/login">
              <button
                class="bg-[#2176ae] hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-xl"
              >
                Login
              </button>
            </router-link>
          </li>
          <li class="text-black hover:text-blue-700">
            <router-link to="/register">
              <button
                class="bg-[#f1f1f1] hover:bg-blue-700 hover:text-white text-black font-bold py-2 px-6 rounded-xl"
              >
                Register
              </button>
            </router-link>
          </li>
        </span>
      </ul>
    </div>

    <div
      class="bg-gray-800/40 fixed top-0 left-0 w-screen h-screen sm:hidden"
      @click="toggleNav()"
      v-if="showMobileNav"
    ></div>

    <div
      id="mobile-nav"
      class="bg-white w-[70vw] pt-4 pb-12 px-2 h-screen shadow-sm fixed right-0 top-0 sm:hidden"
      v-if="showMobileNav"
    >
      <span class="flex justify-between mb-12">
        <p class="font-bold text-gray-800">
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Menu / Menu_Alt_03">
              <path
                id="Vector"
                d="M5 17H13M5 12H19M5 7H13"
                stroke="#000000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        </p>
        <button @click="toggleNav()">back</button>
      </span>
      <section class="flex flex-col h-full justify-between">
        <div class="links flex flex-col gap-4">
          <ul
            class="flex-col mt-8 space-y-4 ml-6 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0"
          >
            <router-link to="/">
              <li class="text-black py-1 hover:text-blue-700">
                Home
              </li></router-link
            >
            <router-link to="/about">
              <li class="text-black py-1 hover:text-blue-700">
                About
              </li></router-link
            >
            <router-link to="/popular">
              <li class="text-black hover:text-blue-700">
                Popular Course
              </li></router-link
            >
            <router-link to="/contact">
              <li class="text-black py-1 hover:text-blue-700">
                Contact Us
              </li></router-link
            >
            <span v-if="isLoggedIn">
              <li class="text-black hover:textblue-700">
                <button
                  @click="logout"
                  class="bg-[#2176ae] hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-xl"
                >
                  Logout
                </button>
              </li>
            </span>
            <span
              v-else
              class="lex-col md:flex md:space-y-0 md:flex-row md:items-center md:space-x-5 md:mt-0"
            >
              <li class="text-black hover:text-[#6366f1]">
                <router-link to="/login">
                  <button
                    class="bg-[#2176ae] hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-xl"
                  >
                    Login
                  </button>
                </router-link>
              </li>
              <li class="text-black hover:text-[#6366f1]">
                <router-link to="/register">
                  <button
                    class="bg-[#f1f1f1] hover:bg-blue-700 hover:text-white text-black font-bold py-2 px-6 rounded-xl"
                  >
                    Register
                  </button>
                </router-link>
              </li>
            </span>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>
    
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "nav-bar",

  data() {
    return {
      showMobileNav: false,
      loggedOut: false,
    };
  },
  computed: {
    ...mapGetters("auth", {
      isLoggedIn: "isAuthenticated",
    }),
  },
  methods: {
    ...mapActions("auth", {
      logoutUser: "LogOut",
    }),
    logout() {
      // await this.$store.dispatch("LogOut");
      this.logoutUser()
        .then((response) => {
          if (response.data === "Logged out.") {
            this.loggedOut = true;
            // this.flashMessage.success({
            //   message: response.data.message,
            //   time: 3000,
            // });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$router.push("/login");
        });
    },
    toggleNav() {
      this.showMobileNav = !this.showMobileNav;
    },
  },
};
</script>
    
    <style lang="scss" scoped>
#mobile-nav {
  background-image: linear-gradient(
    rgba(125, 181, 227, 0.6),
    rgba(27, 67, 120, 0.9)
  );
}
</style>