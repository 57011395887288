<template>
  <div>
    <section class="dark:bg-gray-900">
      <div
        class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
      >
        <div
          class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img
            class="w-[7rem] h-[10rem] mr-2"
            src="@/assets/image/logo.png"
            alt="logo"
          />
        </div>
        <div
          class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700"
        >
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1
              class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
            >
              Sign in to your account
            </h1>
            <form class="space-y-4 md:space-y-6">
              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Your email</label
                >
                <input
                  type="email"
                  name="email"
                  v-model="email"
                  id="email"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@company.com"
                  required=""
                />
              </div>
              <div>
                <label
                  for="password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Password</label
                >
                <input
                  type="password"
                  name="password"
                  v-model="password"
                  id="password"
                  placeholder="••••••••"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required=""
                />
              </div>
              <div class="flex items-center justify-between">
                <div class="flex items-start">
                  <a
                    href="#"
                    class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                    >Forgot password?</a
                  >
                </div>
              </div>
              <button
                type="submit"
                :disabled="!isFormFilled || isLoggingIn"
                :class="{
                  'bg-gray-400': !isFormFilled || isLoggingIn,
                }"
                class="w-full text-white bg-[#2176ae] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                @click="submit"
              >
                {{ isLoggingIn ? "Logging in..." : "Login" }}
              </button>
              <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                Don’t have an account yet?
                <router-link
                  to="/register"
                  class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >Sign up</router-link
                >
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { toast } from "vue3-toastify";

export default {
  name: "login-page",
  data() {
    return {
      email: null,
      password: null,
      isLoggingIn: false,
    };
  },
  computed: {
    isFormFilled() {
      // Check if all required fields are filled and meet the length requirements
      return this.email && this.password && this.password.length >= 6;
    },
  },
  methods: {
    ...mapActions("auth", {
      loginUser: "LogIn",
    }),
    async submit() {
      if (this.isFormFilled) {
        // Use the computed property
        this.isLoggingIn = true;
        const User = new FormData();
        User.append("email", this.email);
        User.append("password", this.password);

        try {
          const loggedIn = await this.loginUser(User);
          console.log(loggedIn);
          toast("Login successful!", { type: "success" });
          if (loggedIn) {
            this.$router.push("/");
          }
        } catch (error) {
          toast(error.response.data.data, { type: "error" });
          console.log(error.response.data.data);
          // this.errors = [error.data.message];
        } finally {
          this.isLoggingIn = false; // Enable the button after the login process completes
        }
      } else {
        // Handle the case when either email or password is null or doesn't meet the length requirement
        toast(
          "Please provide both email and ensure the password is at least 6 characters long.",
          { type: "error" }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  background-image: linear-gradient(
    rgba(125, 181, 227, 0.6),
    rgba(35, 170, 204, 0.9)
  );
}
</style>
