<template>
  <div class="">
    <div class="flex mt-[7rem] mb-10 ml-5 mr-5">
      <div class="w-2/5 p-5 shadow-2xl rounded-xl overflow-y-auto h-full">
        <Loader v-if="!lessonCourse" />
        <div v-if="lessonsCourse">
          <div
            class="mb-2 h-48 overflow-hidden rounded-lg bg-gray-100 shadow-lg sm:mb-4 sm:h-60 md:h-80"
          >
            <img
              :src="lessonsCourse.cover_image.url"
              loading="lazy"
              alt="Photo by Radu Florin"
              class="h-full w-full object-cover object-center"
            />
          </div>

          <div>
            <div class="font-bold text-black uppercase text-md">
              {{ lessonsCourse.title.english }}
            </div>
            <p class="mb-3 text-sm text-gray-500 md:mb-4 md:text-base">
              {{ lessonsCourse.description.english }}
            </p>
          </div>
        </div>
      </div>
      <div class="w-3/5 p-5 ml-10 bg-transparent rounded-xl">
        <div class="">
          <div class="mx-auto max-w-screen-lg px-4 md:px-8">
            <div class="mb-6 flex flex-col gap-4 sm:mb-8 md:gap-6">
              <!-- product - start -->
              <div v-if="!lessonsTopics || lessonsTopics.length === 0">
                <h2
                  class="text-gray-700 justify-center text-lg fixed-center flex items-center gap-2"
                >
                  <Icon icon="ic:round-warning" class="w-8 h-8 text-red-500" />
                  <span> No items found</span>
                </h2>
              </div>
              <div
                v-else
                class="flex flex-wrap overflow-y-auto h-full rounded-lg shadow-2xl sm:gap-y-4 lg:gap-6"
                v-for="(lesson, index) in lessonsTopics"
                :key="index"
                @click="
                  $router.push({
                    name: 'LessonDetail',
                    params: { id: lesson.id },
                  })
                "
              >
                <div
                  class="group object-cover relative block p-10 items-center justify-center h-full"
                >
                  <Icon
                    icon="bxs:caret-right-circle"
                    width="80"
                    color="#6366f1"
                  />
                </div>

                <div class="flex flex-1 flex-col justify-between py-4">
                  <div>
                    <span class="text-sm text-gray-400">{{
                      lesson.created_at
                    }}</span>
                    <div
                      class="mb-1 inline-block text-lg font-bold text-gray-800 transition duration-100 hover:text-gray-500 lg:text-xl"
                    >
                      {{ lesson.title.english }}
                    </div>

                    <span class="block text-gray-500">{{
                      lesson.description.english
                    }}</span>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <!-- totals - end -->
        </div>
      </div>
    </div>

    <!-- end main section -->
  </div>
</template>
  
  <script>
// import NavBar from "@/components/NavBar.vue";
import api from "@/services/api";
import Loader from "@/components/Loader.vue";
export default {
  name: "lessons-page",
  data() {
    return {
      lessonsTopics: null,
      lessonsCourse: null,
      id: null,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.lessonTopic();
    this.lessonCourse();
  },
  methods: {
    lessonTopic() {
      api
        .request({ auth: true })
        .get(`/get-lessons/${this.id}`)
        .then((response) => {
          this.lessonsTopics = response.data.data.data;
          console.log(response.data.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    lessonCourse() {
      api
        .request({ auth: true })
        .get(`/get-lessons/${this.id}`)
        .then((response) => {
          this.lessonsCourse = response.data.data.data[0].course;
          console.log("lessons", response.data.data.data[0].course);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    // NavBar,
    Loader,
  },
};
</script>
  
  <style lang="scss" scoped>
section {
  background-image: linear-gradient(
      rgba(125, 181, 227, 0.9),
      rgba(35, 170, 204, 0.9)
    ),
    url(@/assets/image/courses.jpeg);
}
</style>