<template>
  <div class="bg-gray-200 pb-9 pt-[5rem]">
    <div
      class="container px-4 lg:px-8 mx-auto max-w-screen-xl text-gray-700 overflow-x-hidden"
    >
      <div class="sm:flex items-center sm:space-x-8 mt-16">
        <div data-aos="fade-right" class="sm:w-1/2 relative">
          <div class="flex items-center space-x-20 mb-5">
            <!-- <span class="border border-gray-300 w-14 absolute"></span> -->
            <!-- <h1 class="text-gray-400 tracking-widest text-sm">About Us</h1> -->
          </div>

          <h1 class="font-semibold text-2xl relative z-50 #2f327d lg:pr-10">
            Who
            <span class="text-[#2176ae]">We Are?</span>
          </h1>
          <p class="py-5 lg:pr-32">
            Welcome to Ethio Mind Academy, where we offer neuroscience-based
            training for children, teenagers, and adults in Ethiopia. Our goal
            is to empower over 100 million Ethiopians with the knowledge of how
            their minds work and how to harness this knowledge for a prosperous,
            harmonious, and healthier future society.<br /><br />

            At Ethio Mind Academy, we believe that understanding your mind is
            the key to managing emotions, maintaining mental health, and shaping
            your future. Our e-learning courses cover a wide range of topics,
            from basic neuroscience concepts to advanced techniques for personal
            and professional development.<br /><br />

            Each e-learning course consists of engaging video content, with an
            average duration of 20 minutes per video. We reinforce learning with
            multiple-choice questions to test your understanding and knowledge.
            In addition to the individual e-learning format, we also offer
            class-based training with lectures, group discussions, and final
            answer sessions.<br />
          </p>
        </div>
        <div data-aos="fade-left" class="sm:w-1/2 relative mt-10 sm:mt-0">
          <div
            style="background: #2176ae"
            class="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"
          ></div>
          <img
            class="rounded-xl z-40 relative"
            src="@/assets/image/courses.jpeg"
            alt=""
          />

          <!-- <div
            class="bg-[#2176ae] w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"
          ></div> -->
        </div>
      </div>

      <!-- end about -->
    </div>
  </div>
</template>
