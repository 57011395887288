<template>
  <div>
    <section class="dark:bg-gray-900">
      <div
        class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
      >
        <!-- <div class="flex items-center mb-[6rem] text-2xl font-semibold text-gray-900 dark:text-white"> -->
        <!-- <img class="w-[7rem] h-[10rem] mr-2" src="@/assets/image/logo.png" alt="logo"> -->
        <!-- </div> -->
        <div
          class="w-full mb-[10rem] bg-white rounded-lg shadow dark:border md:mt-[10rem] sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700"
        >
          <div class="p-8 space-y-3 md:space-y-6 sm:p-8">
            <h1
              class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
            >
              Create and account
            </h1>
            <form class="space-y-1 md:space-y-2" @submit.prevent="handleSubmit">
              <div>
                <label
                  for="name"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Full Name</label
                >
                <input
                  type="text"
                  name="text"
                  id="text"
                  v-model="name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Full Name"
                  required=""
                />
              </div>
              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Your email</label
                >
                <input
                  type="email"
                  name="email"
                  id="email"
                  v-model="email"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Your Email"
                  required=""
                />
              </div>
              <div>
                <label
                  for="phone-number"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Your Phone Number</label
                >
                <input
                  type="number"
                  name="phone-number"
                  id="phone-number"
                  v-model="phone"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Phone Number"
                  required=""
                />
              </div>
              <div>
                <label
                  for="phone-number"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Choose Your Gender</label
                >
                <select
                  id="gender"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected>Choose Gender</option>
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                </select>
              </div>

              <div>
                <label
                  for="password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Password</label
                >
                <input
                  type="password"
                  name="password"
                  id="password"
                  v-model="password"
                  placeholder="••••••••"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required=""
                />
              </div>
              <div>
                <label
                  for="confirm-password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Confirm password</label
                >
                <input
                  type="password"
                  name="confirm-password"
                  id="confirm-password"
                  v-model="confirm_password"
                  placeholder="••••••••"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required=""
                />
              </div>

              <button
                type="submit"
                :disabled="isSigningUp || !isFormFilled"
                :class="{ 'bg-gray-400': !isFormFilled }"
                class="w-full text-white bg-[#2176ae] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                {{ isSigningUp ? "Registering..." : "Sign Up" }}
              </button>
              <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                Already have an account?
                <router-link
                  to="/login"
                  class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >Login
                </router-link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { toast } from "vue3-toastify";
export default {
  name: "register-page",
  data() {
    return {
      email: null,
      name: null,
      gender: null,
      phone: null,
      password: null,
      confirm_password: null,
      isSigningUp: false,
    };
  },
  computed: {
    isFormFilled() {
      // Check if all required fields are filled
      return (
        this.name &&
        this.email &&
        this.phone &&
        this.password &&
        this.password.length >= 6 &&
        this.confirm_password &&
        this.confirm_password.length >= 6
      );
    },
  },
  methods: {
    ...mapActions("auth", {
      register: "Register",
    }),

    async handleSubmit() {
      this.isSigningUp = true;

      try {
        const User = new FormData();
        User.append("name", this.name);
        User.append("email", this.email);
        User.append("gender", this.gender);
        User.append("phone", this.phone);
        User.append("password", this.password);
        User.append("confirm_password", this.confirm_password);

        // Attempt to register
        const response = await this.register(User);

        console.log(response);
        toast("Registered successfully!", { type: "success" });
        this.$router.push("/login");
      } catch (error) {
        console.error(error);

        // Handle the error here (e.g., show a toast message)
        toast(error.response.data.data, { type: "error" });
      } finally {
        // Ensure that isSigningUp is reset even if there's an error
        this.isSigningUp = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  background-image: linear-gradient(
    rgba(125, 181, 227, 0.6),
    rgba(35, 170, 204, 0.9)
  );
  background-position: 100% 100%;
}
</style>