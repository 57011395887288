<template>
  <nav-bar
    v-if="!$route.path.includes('/login') && !$route.path.includes('/register')"
  />
  <router-view />
  <Footer
    v-if="!$route.path.includes('/login') && !$route.path.includes('/register')"
  />
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    NavBar,
    Footer,
  },
};
</script>

<style>
</style>
