<template>
  <div class="bg-[#f1f1f1] h-screen">
    <!-- landing -->
    <section
      class="flex flex-col p-8 mt-20 justify-between gap-6 sm:gap-10 md:gap-16 lg:flex-row"
    >
      <div class="flex flex-col justify-between xl:w-5/12">
        <div class="sm:text-center lg:py-8 lg:text-left xl:py-8">
          <p
            class="mb-4 font-semibold text-[#2176ae] md:mb-6 md:text-lg xl:text-xl"
          >
            Very proud to introduce
          </p>

          <h1
            class="text-black-800 mb-8 text-4xl font-bold sm:text-5xl md:mb-12 md:text-6xl"
          >
            Children Topic
          </h1>

          <div
            class="flex flex-col gap-2.5 sm:flex-row sm:justify-center lg:justify-start"
          >
            <!-- <a
              href="#"
              class="inline-block rounded-lg bg-[#2176ae] px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-[#0f4c81] focus-visible:ring active:bg-indigo-700 md:text-base"
              >Start now</a
            >

            <a
              href="#"
              class="inline-block rounded-lg bg-gray-200 px-8 py-3 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-300 focus-visible:ring active:text-gray-700 md:text-base"
              >Take tour</a
            > -->
          </div>
        </div>
      </div>
    </section>
    <!-- end landing -->
    <Loader v-if="!childrenTopics" />
    <div class="bg-[#f1f1f1] py-6 sm:py-8 lg:py-12 relative">
      <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
        <div
          class="grid gap-4 sm:grid-cols-2 md:gap-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-8"
        >
          <div v-if="!childrenTopics || childrenTopics.length === 0">
            <h2
              class="text-gray-700 justify-center text-lg fixed-center flex items-center gap-2"
            >
              <Icon icon="ic:round-warning" class="w-8 h-8 text-red-500" />
              <span> No items found</span>
            </h2>
          </div>
          <div
            v-else
            class="group relative flex h-[100%] flex-col overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-64 xl:h-96"
            v-for="(children, index) in childrenTopics"
            :key="index"
            @click="
              $router.push({
                name: 'CategoryList',
                params: { id: children.id },
              })
            "
          >
            <img
              :src="children.cover_image.url"
              loading="lazy"
              alt="Photo by Lorenzo Herrera"
              class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
            />

            <div
              class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 to-transparent md:via-transparent"
            ></div>

            <div class="relative mt-auto p-4">
              <span class="block text-sm text-gray-200">{{
                children.updated_at
              }}</span>
              <h2
                class="mb-2 text-xl font-semibold text-white transition duration-100"
              >
                {{ children.title.english }}
              </h2>

              <!-- <span class="font-semibold text-indigo-300">Read more</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import api from "@/services/api";
import Loader from "@/components/Loader.vue";
export default {
  name: "category-page",
  data() {
    return {
      childrenTopics: null,
    };
  },
  components: {
    Loader,
  },
  mounted() {
    this.childrenTopic();
  },
  methods: {
    childrenTopic() {
      api
        .request({ auth: true })
        .get(`/children-topics`)
        .then((response) => {
          this.childrenTopics = response.data.data.data;
          console.log(response.data.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
section {
  background-image: linear-gradient(
      rgba(125, 181, 227, 0.6),
      rgba(35, 170, 204, 0.9)
    ),
    url("@/assets/image/children.jpeg");
}
</style>