<template>
  <div>
    <!-- <NavBar/> -->
    <Landing />
    <Category />
    <NewCourses />
    <!-- <CallToAction /> -->
    <!-- <PopularCourse /> -->
    <!-- <Footer/> -->
  </div>
</template>

<script>
// import NavBar from '@/components/NavBar.vue'
import Landing from "@/components/Landing.vue";
// import PopularCourse from "@/components/PopularCourse.vue";
import Category from "@/components/Category.vue";
// import CallToAction from "@/components/CallToAction.vue";
import NewCourses from "@/components/NewCourses.vue";
// import Footer from '@/components/Footer.vue'
export default {
  name: "home-page",
  components: {
    // NavBar,
    Landing,
    // PopularCourse,
    Category,
    // CallToAction,
    NewCourses,
    // Footer
  },
};
</script>

<style lang="scss" scoped>
</style>